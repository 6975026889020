<template>
    <section class="listado-proveedores overflow-auto scroll-none px-3">
        <navbar-admin  titulo="Proveedores" />
        <titulo-divisor titulo="Proveedores">
            <div class="row">
              
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{this.cantFiltros}}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltroSinToggle" />
                        </div>
                    </div>
                </div>
                <div class="col-auto my-auto px-1">
                    <button 
                        class="btn btn-general f-12 px-4"
                        :class="permitAction('proveedores.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click.stop="functionPermitAction('proveedores.crear', crearProveedor)"
                    >Crear proveedor</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="d-flex justify-content-lg-end">
            <!-- <search class="w-50" :data-to-search="filter.buscar" :auto-search="false" placeholder="Buscar proveedor por cualquier campo" @filtered="refreshProviderList" /> -->
            <el-input v-model="filters.buscar" class="w-25"  placeholder="Buscar proveedores" size="small" @keypress.native.enter="refreshProviderList"/>
        </div>
        <tabla-general 
        altura-tabla="calc(100vh - 430px)"
        :usarServidor="true" 
        :mostrar-buscador="false" 
        :usarPaginacion="true" 
        :servidorData="proveedores"
        @paginar="listaPvs"
        >
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="verProveedor(scope.row.id)">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Ciudad principal" prop="ciudad">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.ciudad ? scope.row.ciudad.ciudad : '' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Dirección" prop="direccion">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.direccion }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Responsable" prop="responsable">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.responsable }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Nombre proveedor</p>
                    <el-select v-model="filter.id_proveedor" placeholder="Buscar proveedores, OC" size="small" class="w-100">
                        <el-option v-for="proveedor in select_providers" :key="proveedor.id" :label="proveedor.proveedor" :value="proveedor.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Responsable</p>
                    <el-select v-model="filter.id_responsable" placeholder="Buscar responsable" size="small" class="w-100">
                        <el-option v-for="responsable in select_responsables_proveedores" :key="responsable.id" :label="responsable.nombre" :value="responsable.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Ciudad</p>
                    <!-- <el-select v-model="citySelect" placeholder="Seleccionar ciudad" size="small" class="w-100">
                        <el-option v-for="(ciudad, index) in ciudades" :key="index" :label="ciudad" :value="ciudad" />
                    </el-select> -->
                    <select-ciudades v-model="filter.ciudad" class="w-100" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="refreshProviderList"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-provider ref="modalCrearProveedor" />
    </section>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import modalProvider from './partials/modalProvider'
export default {
    components: { modalProvider },
    data(){
        return{
            buscarListado:'',
            citySelect: '',
            typeMaterialSelect: '',
            listadoProveedores:[
                {
                    id: 1,
                    name: 'Aceros S.ciudades22 # 56-7 manzana H',
                    responsable: 'Dario Bermúdez Niño',
                },
                {
                    id: 2,
                    name: 'Taller Ensambles',
                    ciudad: 'Medellín',
                    direccion: 'cra 22 # 56-7 manzana H',
                    responsable: 'Eduard Cala',
                },
            ],
            ciudades: [
                'Bucaramanga',
                'Bogotá',
                'Cali',
                'Barranquilla'
            ],
        }
    },
    computed: {
        ...mapGetters({
            proveedores: 'proveedores/proveedores/proveedores',
            filter: 'proveedores/proveedores/filter',
            materialTypes: 'configurar/almacen/tipos_material/materialTypes',
            select_providers: 'selects/selects/select_providers',
            select_responsables_proveedores: 'selects/selects/select_responsables_proveedores',
        }),
        cantFiltros(){
            return [this.citySelect, this.typeMaterialSelect].filter(filter => filter).length
        },
        filters: {
            get(){ return this.filter },
            set(val){ this.set_filter(val) }
        },
        query: {
            get(){ return this.filter.buscar },
            set(val){ this.set_filter(val) }
        },
    },
    methods:{
        ...mapActions({
            obtenerListaProveedores: 'proveedores/proveedores/Action_list_providers',
            Action_clear_filter: 'proveedores/proveedores/Action_clear_filter',
            obtenerMateriales: 'configurar/almacen/tipos_material/Action_list_material_types',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            getSelects: 'selects/selects/sync',
            Action_get_select_providers: 'selects/selects/Action_get_select_providers',
            Action_get_select_proveedores: 'selects/selects/Action_get_select_proveedores'

        }),

        ...mapMutations({
            set_filter: 'cotizacion/cotizacionesVigentes/set_filter',
            setProviderList: 'proveedores/proveedores/set_providers_data',
            regenerateProviderList: 'proveedores/proveedores/set_regenerate_providers_data',
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async listaPvs(page=1){
            await this.obtenerListaProveedores({page})
        },
        async filtrarProveedores(){
            const query = {
                ciudad: this.citySelect === '' ? undefined: this.citySelect,
                tipo_material: this.typeMaterialSelect === '' ? undefined: this.typeMaterialSelect,
            }
            await this.obtenerListaProveedores(query)
            this.$router.push({query})
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro(){
            this.Action_clear_filter()
            this.$router.push({query: {}})
            this.citySelect = ''
            this.typeMaterialSelect = ''
            await this.obtenerListaProveedores()
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltroSinToggle(){
            this.$router.push({query: {}})
            this.citySelect = ''
            this.typeMaterialSelect = ''
            await this.obtenerListaProveedores()
        },
        crearProveedor(){
            this.$refs.modalCrearProveedor.toggle()
        },
        verProveedor(id_proveedor){
            const proveedor = this.proveedores.data.filter(proveedor => proveedor.id == id_proveedor)[0]
            this.$router.push({ name: 'proveedores.ver', params: { id_proveedor, proveedor } })
        },
        async refreshProviderList(){
            await this.obtenerListaProveedores()
        }

        // refreshProviderList(data){
        //     if(data.length){
        //         this.setProviderList(data)
        //     } else {
        //        this.regenerateProviderList()
        //     }
        // }
    },
    async mounted(){
        this.getBreadcumbs(['proveedores.listado'])
        this.getSelects(['Action_get_select_materials','Action_get_selects_responsible_users'])
        this.Action_get_select_providers()
        this.Action_get_select_proveedores()
        const { query } = this.$route
        if(Object.keys(query).length){
            const { ciudad, tipo_material } = query
            this.citySelect = ciudad
            this.typeMaterialSelect = tipo_material
            await this.filtrarProveedores()
        } else {
            await this.obtenerMateriales()
            await this.listaPvs()
        }
    }
}
</script>

<style lang="scss" scoped>
.listado-proveedores{
    height: calc(100vh - 225px);
}
</style>